<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">进销存</el-breadcrumb-item>
        <el-breadcrumb-item>款式信息（SKU）</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="SKU编号" prop="detailCode">
          <el-col style="width: 120px">
            <el-input v-model="detailCode" @keyup.enter="fetchs"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="Plus" @click="add()">
        新增
      </el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:productImg="scope">
        <el-image style="height: 32px; width: 32px" lazy :src="apiUrl + 'static/code/' + scope.row.detailCode + '?t=0'"
          :preview-src-list="[apiUrl + 'static/code/' + scope.row.detailCode + '?t=1']">
          <template #error>
            <img style="width: 100%" src="~@/assets/imgunfine.jpg" alt="" />
          </template>
        </el-image>
      </template>

      <template v-slot:status="scope">
        {{ scope.row.status == "enabled" ? "正常" : scope.row.status == "disabled" ? "禁用" : "其他" }}
      </template>

      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" type="primary" link size="small">
          [编辑]
        </el-button>
        <el-button @click="del(scope.row)" type="primary" link size="small">
          [删除]
        </el-button>
      </template>
    </static-table>

    <static-dialog ref="dialog" class="dialog" title="款式信息维护SKU" :opened="opened" :ok="submit">
      <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" v-if="!forms.id">
            <el-form-item label="归属的款式" prop="chooseSpu">
              <el-autocomplete style="width: 95%" v-model="chooseSpu.value" :fetch-suggestions="searchSpuAsync"
                placeholder="请输入Spu信息内容" @select="chooseSpuHandle">
              </el-autocomplete>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" v-if="!forms.id">
            <el-form-item label="" label-width="0" style="max-width: 85%">
              <el-input v-model="chooseSpu.productCode" autocomplete="off" disabled placeholder="SPU编号">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="款式大类" prop="category">
              <el-select v-model="forms.category" placeholder="请选择" disabled>
                <el-option v-for="item in dict.categorys" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="对应材质" prop="metalCode">
              <el-select v-model="forms.metalCode" placeholder="请选择" @change="chooseOption($event, dict.metals)"
                :disabled="forms.id">
                <el-option v-for="item in dict.metals" :key="item.id" :label="item.value" :value="item.name">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
            <el-form-item label="款式名称" prop="detailName" style="width: 95%">
              <el-input v-model="forms.detailName" autocomplete="off">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
            <el-form-item label="" label-width="0" prop="detailCode">
              <el-input v-model="forms.detailCode" autocomplete="off" placeholder="SKU编号" disabled>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="forms.detailCode">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="自定义款号" prop="serialCode">
              <el-input v-model="forms.serialCode" autocomplete="off" placeholder="自定义款号">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="规格" prop="detailUnit" style="max-width: 85%">
              <el-input v-model="forms.detailUnit" autocomplete="off">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="总质量" prop="totalWeight">
              <el-input v-model="forms.totalWeight" autocomplete="off">
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="状态" prop="status">
              <el-select v-model="forms.status" placeholder="请选择">
                <el-option label="正常" value="enabled"> </el-option>
                <el-option label="禁用" value="disabled"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="所属品牌" prop="brandName" style="max-width: 85%">
              <el-select v-model="forms.brandName" filterable placeholder="请选择">
                <el-option v-for="item in dict.brands" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="品牌款号" prop="brandModelCode" style="max-width: 85%">
              <el-input v-model="forms.brandModelCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="款式工厂" prop="factoryName" style="max-width: 85%">
              <el-select v-model="forms.factoryName" filterable placeholder="请选择">
                <el-option v-for="item in dict.factorys" :key="item.id" :label="item.name" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="工厂款号" prop="factoryModelCode" style="max-width: 85%">
              <el-input v-model="forms.factoryModelCode" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="采购参考价" prop="purchasePrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.purchasePrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <el-form-item label="售卖价" prop="sellPrice" style="max-width: 70%">
              <el-input type="number" v-model="forms.sellPrice" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>

          <!-- <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="平台上架信息">
              123
            </el-form-item>
          </el-col> -->
        </el-row>
      </el-form>
    </static-dialog>
  </el-card>
</template>
<script>
import { fetchSpuList, fetchSkuList, fetchSkuDetail, submitSku, delSku, fetchDetailCode } from "@/api/product";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
export default {
  name: "SkuList",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      apiUrl: "",
      detailCode: "",
      chooseSpu: {},
      skuDetailDialogVisible: false,

      querys: {
        keyword: "",
        filters: [],
        columns: [
          { type: "index", label: "序号" },
          { prop: "category", label: "大类名称", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "productCode", label: "SPU编号", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "detailCode", label: "SKU编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { prop: "serialCode", label: "自定编号", sortable: true, searchable: true, width: "130", showOverflowTooltip: true, },
          { type: "template", prop: "productImg", label: "图片", width: "70", },
          { prop: "detailName", label: "SKU名称", sortable: true, searchable: true, width: "160", showOverflowTooltip: true },
          { prop: "detailUnit", label: "款式规格", sortable: true, searchable: true, width: "100", showOverflowTooltip: true },
          { prop: "brandName", label: "品牌名称", sortable: true, searchable: true, width: "90", showOverflowTooltip: true, },
          { prop: "brandModelCode", label: "品牌款号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "factoryName", label: "工厂名", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "factoryModelCode", label: "工厂款号", sortable: true, searchable: true, width: "110", showOverflowTooltip: true, },
          { prop: "purchasePrice", label: "采购价", sortable: true, width: "80", align: "right", },
          { prop: "sellPrice", label: "售价", sortable: true, width: "80", align: "right", },
          { type: "template", prop: "status", label: "状态", width: "90", },
          { prop: "createDate", label: "创建时间", sortable: true, width: "140", showOverflowTooltip: true },
          { prop: "createUserName", label: "创建人", sortable: true, searchable: true, width: "100", },
          { type: "toolbar", label: "操作", width: "120" },
        ],
      },
      pager: {
        sidx: "id", //默认的排序字段
        sord: "descending",
      },
      currRow: null,
      centerDialogVisible: false,
      dialogWidth: "30%",

      dict: {
        categorys: [],
        brands: [],
        factorys: [],
        metal: [],
      },
      forms: {},
      labelWidth: "110px",
      rules: {
        category: [
          { required: true, message: "请选择款式大类", trigger: "blur" },
        ],
        metalCode: [
          { required: true, message: "请选择款式的材质", trigger: "blur" },
        ],
        detailName: [
          { required: true, message: "请输入款式名称", trigger: "blur" },
          { min: 1, max: 30, message: "长度在1-30之间", trigger: "blur" },
        ],
        purchasePrice: [
          { required: true, message: "请输入采购参考价格", trigger: "blur" },
        ],
        sellPrice: [
          { required: true, message: "请输入售卖预估价格", trigger: "blur" },
        ],
        brandName: [
          { required: true, message: "请选择所属品牌", trigger: "blur" },
        ],
        factoryName: [
          { required: true, message: "请选择对应工厂", trigger: "blur" },
        ],
        status: [
          { required: true, message: "请选择款式状态", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.fetchs();

    this.$kaung.dictionary("stock", "category").then((res) => { this.dict.categorys = res; });
    this.$kaung.dictionary("stock", "brand").then((res) => { this.dict.brands = res; });
    this.$kaung.dictionary("stock", "factory").then((res) => { this.dict.factorys = res; });
    this.$kaung.dictionary("stock", "metal").then((res) => { this.dict.metals = res; });
    this.apiUrl = process.env.VUE_APP_BASEURL;
  },
  methods: {
    //搜索归属的SPU（请求后台数据）
    searchSpuAsync(queryString, callback) {
      console.log(queryString, callback);

      let search = {
        page: 1,
        rows: 10,
        columns: [
          { prop: "productCode", searchable: true },
          { prop: "productName", searchable: true },
          { prop: "category", searchable: true },
          { prop: "productMass", searchable: true },
          { prop: "brandCode", searchable: true },
          { prop: "brandName", searchable: true },
          { prop: "brandProductCode", searchable: true },
          { prop: "factoryCode", searchable: true },
          { prop: "factoryName", searchable: true },
          { prop: "factoryProductCode", searchable: true },
          { prop: "purchasePrice", searchable: true },
          { prop: "sellPrice", searchable: true },
        ],
        keyword: this.chooseSpu.value,
      };
      fetchSpuList(search).then((res) => {
        console.log(res);
        //res.data.data
        let inputOptions = [];
        res.data.data.forEach(function (item) {
          inputOptions.push({
            value: item.productName,
            ...item,
          });
        });

        callback(inputOptions);
      });
    },
    //选择选中事件
    chooseSpuHandle(item) {
      console.log(item);
      this.forms.detailCode = "";
      this.forms.metalCode = "";

      this.chooseSpu = item;
      this.forms.category = item.category;
      this.forms.detailName = item.productName;
      this.forms.productID = item.id;
      this.forms.productCode = item.productCode;

      this.forms.totalWeight = item.productMass;

      this.forms.brandCode = item.brandCode;
      this.forms.brandName = item.brandName;
      this.forms.brandModelCode = item.brandProductCode;
      this.forms.factoryCode = item.factoryCode;
      this.forms.factoryName = item.factoryName;
      this.forms.factoryModelCode = item.factoryProductCode;

      this.forms.purchasePrice = item.purchasePrice;
      this.forms.sellPrice = item.sellPrice;

      this.forms.status = "enabled";
    },
    add() {
      this.currRow = {};
      this.$refs.dialog.toggle();
    },
    edit(row) {
      this.currRow = row;
      this.$refs.dialog.toggle();
    },
    del(row) {
      this.currRow = row;
      let that = this;

      this.$confirm("此操作将删除本行数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delSku(that.currRow.id).then((res) => {
          that.$message({
            type: res.type,
            message: res.content,
          });
          that.fetchs();
        });
      });
    },
    chooseOption(value, options) {
      let items = options.filter(function (item) {
        return item.name == value;
      });
      if (items && items.length > 0) {
        let that = this;
        fetchDetailCode({
          productCode: this.chooseSpu.productCode,
          metalCode: items[0].name,
        }).then((res) => {
          that.forms.detailCode = res.data;
        });
      }
    },
    opened() {
      let that = this;
      this.forms = {};
      this.chooseSpu = {};
      if (this.currRow && this.currRow.id) {
        fetchSkuDetail(this.currRow.id).then((res) => {
          that.forms = { ...res.data };
        });
      }
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        let chooseBrands = that.dict.brands.filter(function (item) {
          return item.value == that.forms.brandName;
        });
        let chooseFactorys = that.dict.factorys.filter(function (item) {
          return item.value == that.forms.factoryName;
        });
        if (chooseBrands.length == 0 || chooseFactorys.length == 0) {
          that.$message({
            message: "请重新选择工厂和品牌",
          });
          return;
        }

        // let chooseMetals = that.dict.metals.filter(function (item) {
        //   return item.value == that.forms.metalCode;
        // });

        that.forms.brandCode = chooseBrands[0].name;
        that.forms.factoryCode = chooseFactorys[0].name;
        submitSku(that.forms).then((res) => {
          that.$message({
            type: res.type,
            message: res.content,
          });

          if (res.data) {
            that.fetchs();
            that.$refs.dialog.toggle();
          }
        });
      });
    },
    fetchs() {
      this.querys.filters = [];
      if (this.detailCode)
        this.querys.filters.push({
          name: "detailCode",
          value: "%" + this.detailCode + "%",
          operate: 5,
        });
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };

      fetchSkuList(search).then((res) => {
        this.pager = { ...res.data };
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },
    //控制dialog宽度与子组件控制关闭弹窗
    getdialogWidth(data) {
      if (data === "80%" || data === "30%") {
        this.dialogWidth = data;
      } else {
        this.centerDialogVisible = false;
      }
    },
  },
};
</script>
<style lang="less">
.xlsdialog {
  .el-dialog__body {
    max-height: 80vh;
    overflow-y: auto;
  }
}

.el-form .el-date-editor {
  width: 220px;
}
</style>>
